export enum PagesType {
  Home,
  Training,
  Joining,
  Members,
  Partners,
  Member,
  Mentions,
  Contact,
  News,
  NewsDetails,
  Projects,
  ProjectDetails,
}
