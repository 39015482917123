import Image from 'next/image';
import Link from 'next/link';
import React, { FunctionComponent, useEffect, useState } from 'react';

import { PagesType } from 'common/pages-type';
import useMediaQuery from 'helpers/use-media-query';

import styles from './menu.module.scss';

const HOME_LINK = '/';
const TRAINING_LINK = '/training';
const POSTS_LINK = '/news';
const JOINING_LINK = '/joining';
const MEMBERS_LINK = '/members';
const PARTNERS_LINK = '/partners';
const CONTACT_LINK = '/contact';
const PROJECTS_LINK = '/projects';

type Props = {
  origin: PagesType;
  hideHome: boolean;
  forceInline: boolean;
  previewMode: boolean;
};

const NavItemMenu: FunctionComponent<{
  url: string;
  label: string;
  isActif: boolean;
}> = ({ url, label, isActif }) => {
  const [link, setLinks] = useState<string>(url);

  const queryStringParameters = null;

  useEffect(() => {
    setLinks(queryStringParameters ? url + '?' + queryStringParameters : url);
  }, [url]);

  return (
    <li className={isActif ? styles['active'] : ''}>
      <Link href={link}>{label}</Link>
    </li>
  );
};

const NavMenu: FunctionComponent<{
  origin: PagesType;
  hideHome: boolean;
}> = ({ origin, hideHome }) => {
  return (
    <nav>
      <ul>
        {!hideHome && (
          <NavItemMenu
            label="Accueil"
            url={HOME_LINK}
            isActif={origin == PagesType.Home}></NavItemMenu>
        )}
        {/* <NavItemMenu
          label="Entrainements"
          url={TRAINING_LINK}
          isActif={origin == PagesType.Training}></NavItemMenu> */}
        <NavItemMenu
          label="Actualités"
          url={POSTS_LINK}
          isActif={origin == PagesType.News}></NavItemMenu>
        <NavItemMenu
          label="Projets"
          url={PROJECTS_LINK}
          isActif={origin == PagesType.Projects}></NavItemMenu>
        <NavItemMenu
          label="Membres"
          url={MEMBERS_LINK}
          isActif={origin == PagesType.Members}></NavItemMenu>
        <NavItemMenu
          label="Adhésion"
          url={JOINING_LINK}
          isActif={origin == PagesType.Joining}></NavItemMenu>
        <NavItemMenu
          label="Partenaires"
          url={PARTNERS_LINK}
          isActif={origin == PagesType.Partners}></NavItemMenu>
        <NavItemMenu
          label="Contact"
          url={CONTACT_LINK}
          isActif={origin == PagesType.Contact}></NavItemMenu>
      </ul>
    </nav>
  );
};

export default function Menu({
  origin,
  hideHome,
  forceInline,
  previewMode,
}: Props) {
  const [isActive, setIsActive] = useState(false);

  const toggleMenu = () => {
    setIsActive(!isActive);
  };

  const isBreakpoint = useMediaQuery(768);

  return (
    <div className={styles['container']}>
      {isBreakpoint && !forceInline ? (
        <div className={styles['menu-mobile']}>
          <button onClick={toggleMenu}>
            <Image
              src={isActive ? '/icon/close.svg' : '/icon/menu.svg'}
              width={25}
              height={25}
              alt="Ouvrir menu"
              unoptimized
            />
          </button>
          {previewMode && (
            <Link href="/api/exit-preview" className={styles['preview-mode']}>
              Mode prévisualisation
            </Link>
          )}
          {isActive && <NavMenu origin={origin} hideHome={hideHome}></NavMenu>}
        </div>
      ) : (
        <div className={styles['menu-desktop']}>
          {previewMode && (
            <Link href="/api/exit-preview" className={styles['preview-mode']}>
              Mode prévisualisation
            </Link>
          )}
          <NavMenu origin={origin} hideHome={hideHome}></NavMenu>
        </div>
      )}
    </div>
  );
}
